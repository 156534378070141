<template>
  <div style="z-index:5000;">
      <vxe-pulldown ref="xDown4" transfer>
        <template v-slot>
          <vxe-input style="width: 100%;" v-model="row[currentcolumn]" size="mini" suffix-icon="fa fa-search" placeholder=""
           @keyup="keyupEvent4"
            @focus="focusEvent4" @suffix-click="suffixClick4" v-bind:readonly="disabled2" :edit-render="{name: 'input'}"></vxe-input>
        </template>
        <template v-slot:dropdown>
          <div class="my-dropdown4" style="z-index:2999;">
            <vxe-grid  
              border
              resizable
              show-overflow
              formatter
              size="mini"
              highlight-current-row
              highlight-hover-row
              font
              :header-cell-style="colStyle" :cell-style="rowStyle" stripe  auto-resize width="auto" height="auto" style="z-index:3000;" :loading="loading4"
              :data="tableData4" :columns="tableColumn4" @cell-click="cellClickEvent4" >
            </vxe-grid>
          </div>
        </template>
      </vxe-pulldown>  
  </div>
</template>
<script>
  import ax from "@/js/ax.js";
  export default {
    name: "propertycolumnddtable",
    props: ["disabled1","value1","codevalue", "currentcolumn", "row", "docset","selectype","url"],
    data() {
      return {
        value4: '',
        valuename4: "",
        tableColumn4: [],
        loading4: false,
        tableData4: [],
        tableList4: [],
        params: {
          wheres: "",
          pageindex: 1,
          pagesize: 10,
          orderby: "",
          total: 0,
          currentcolumn: this.currentcolumn
          
          
        },
        disabled3: this.disabled1,
        disabled2:false,
        filterdata: {
          openwindowdata: {},
          data: [],
          selectcolumn: [],
          selectoperation: []
        },
       
      }
    },
    created() {
      this.keyupEvent4()
    },
    mounted() {
      this.tableColumn4.length = 0;
      let titletexts = this.docset["titletext"].split(",");
      let showcolumns = this.docset["showcolumn"].split(",");
      if(this.disabled3=='view'&&this.$router.flagecode=='view'){
        this.disabled2=true;
      }else{
        this.disabled2=false;
      }
      for (let item of titletexts) {
        for (let i of showcolumns) {
          let cs = item.split("=");
          if (cs[0] == i) {
            let temp = {
              field: cs[0],
              title: cs[1]
            }
            this.tableColumn4.push(temp);
          }
        }
      }
    //  alert(this.disabled3);
    
    },
    methods: {
      rowStyle(row, rowIndex) {
        return {
          padding:'1px 0 1px 0',
          fontSize: '10pt',
          height:'20px',
        }
      },
      colStyle(row, rowIndex) {
        return {
          fontSize: '10pt',
        }
      },
      focusEvent4() {
        if(this.disabled3=='view'&&this.$router.flagecode=='view'){
          this.disabled2=true;
        }else{
          this.disabled2=false;
          this.$refs.xDown4.showPanel()
        }
        
      },
      keyupEvent4() {
        if(this.disabled3=='view'&&this.$router.flagecode=='view'){
          this.disabled2=true;
        }else{
          this.disabled2=false;
            if (this.row[this.currentcolumn] != null //&& this.row[this.currentcolumn].length >= 2
          ) {
            this.selectpage();
          } else {
            this.tableList4.length = 0;
            this.temp();
          }
        }
       
      },
      temp() {
        const {
          value4
        } = this
        this.loading4 = true

        setTimeout(() => {
          this.loading4 = false
          if (value4) {
            this.tableData4 = this.tableList4.filter(row => row.name.indexOf(value4) > -1)
          } else {
            this.tableData4 = this.tableList4.slice(0)
          }
        }, 100)
      },
      suffixClick4() {
        this.$refs.xDown4.togglePanel()
      },
      cellClickEvent4({row}) {
        let setvalues = this.docset.setvalue.split(",");
        for(let item of setvalues){
          let temp = item.split("=");
          this.row[temp[0]] = row[temp[1]]
        }
        this.$refs.xDown4.hidePanel()
      },
      selectpage() {
        this.getwheres1();
         let _this = this;
        // let _data = bods.app.data(this.params)
        // _data.table = _this.source.table;

        let p ={
          "selectype":this.selectype,
          "name":this.row[this.currentcolumn],
          "codevalue":this.codevalue,
          "value1":this.value1
        }
        // console.log(p)
        ax.base.post(_this.url,p).then(data=>{
          // console.log(_this.url);
          //  console.log(p);
          //  console.log(data.data.rs);
          _this.filterdata.data = data.data.rs;
          _this.tableList4 = data.data.rs;
          _this.temp();
        })

        // bods.spost("/voucher/list", "getdata", _data, function (data) {
        //   _this.filterdata.data = data.rows;
        //   _this.params.total = data.count;
        //   _this.tableList4 = data.rows;
        //   _this.temp();
        // });
      },
      getwheres1() {
        let wheres = "";
        wheres = this.currentcolumn + " like '%" + this.row[this.currentcolumn] + "%'";
        this.params.wheres = wheres;
      },
      getwheres() {
        let wheres = "";
        for (let key in this.filterdata.openwindowdata) {
          if (this.filterdata.openwindowdata[key] != "" && this.filterdata.openwindowdata[key] != undefined) {
            let where =
              key + " like '%" + this.filterdata.openwindowdata[key] + "%'";
            if (wheres.length == 0) {
              wheres = where;
            } else {
              wheres += " and " + where;
            }
          }
        }
        this.params.wheres = wheres;
      },
      
    }
  }

</script>
<style>
  .my-dropdown1 {
    height: 600px;
    overflow: auto;
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
  }

  .list-item1:hover {
    background-color: #f5f7fa;
  }

  .my-dropdown2 {
    border-radius: 2px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
  }

  .list-item2:hover {
    background-color: #f5f7fa;
  }

  .my-dropdown3 {
    width: 700px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }

  .my-dropdown4 {
    width: 1140px;
    height: 300px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }

</style>
